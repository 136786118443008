import axios from 'axios';
import axiosRetry from 'axios-retry';
import { logOut } from '../../common/state/auth/reducers';
import { getCookie, requestInterceptor, responseInterceptor } from '../../helpers/utils';

const idToken = getCookie('idToken');

axiosRetry(axios, { retries: 3, retryCondition: axiosRetry.isSafeRequestError });
axios.defaults.withCredentials = false;
axios.interceptors.request.use(requestInterceptor);
axios.interceptors.response.use(responseInterceptor);

const { fetch: origFetch } = window;
window.fetch = async (...args) => {
  if (!idToken) {
    logOut();
  }
  const response = await origFetch(...args);
  if (response && response.status === 401) {
    logOut();
  }
  return response;
};

export async function get(url) {
  //HTTP/2 does not add statusText OK to the response so basing a success on that will be faulty
  try {
    const res = await axios.get(url);
    return res.data;
  } catch (err) {
    throw err;
  }
}

export async function post(url, succeed, fail, data) {
  try {
    const res = await axios.post(url, data);
    succeed(res.data);
  } catch (err) {
    return fail(err);
  }
}

export async function patch(url, succeed, fail, data) {
  try {
    const res = await axios.patch(url, data);
    succeed(res.data);
  } catch (err) {
    return fail(err);
  }
}

export async function put(url, succeed, fail, data) {
  try {
    const res = await axios.put(url, data);
    succeed(res.data);
  } catch (err) {
    return fail(err);
  }
}
