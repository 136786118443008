import Cookies from 'js-cookie';
import { ReactComponent as InventoryIcon } from '../images/Inventory.svg';
import { ReactComponent as HomeInventoryIcon } from '../images/InventoryV2.svg';
import { ReactComponent as HealthServicesIcon } from '../images/HealthServices.svg';
import { ReactComponent as HomeHealthServicesIcon } from '../images/HealthServices2.svg';
import { ReactComponent as FacilityInsightsIcon } from '../images/FacilityInsights.svg';
import { ReactComponent as HomeFacilityInsightsIcon } from '../images/FacilityInsightsV2.svg';
import { ReactComponent as POSIcon } from '../images/POS.svg';
import { ReactComponent as VDLIcon } from '../images/VDL.svg';
import { ReactComponent as HomePOSIcon } from '../images/POSV2.svg';
import { ReactComponent as SalesIcon } from '../images/SalesHistory.svg';
import { ReactComponent as HomeSalesIcon } from '../images/SalesHistoryV2.svg';
import { ReactComponent as HomeOrdersIcon } from '../images/OrderV2.svg';
import { ReactComponent as OrdersIcon } from '../images/Order.svg';
import { ReactComponent as MemberManagementIcon } from '../images/Member.svg';
import { ReactComponent as WarehouseIcon } from '../images/Warehouse.svg';
import { ReactComponent as HomeWarehouseIcon } from '../images/WarehouseV2.svg';
import { ReactComponent as HomeMemberManagementIcon } from '../images/MemberV2.svg';
import { ReactComponent as MemberDirectoryIcon } from '../images/MemberDirectoryV2.svg';
import {
  LOGISTICS_APP_URL,
  LOGOUT_URL,
  INVENTORY_OPERATIONS_TRACKING_URL,
  INVENTORY_OPERATION_TYPES,
  ORDERS_MODULE_COUNTRY_CONFIGS,
  FACILITY_TIME_ZONE_UTC_OFFSETS
} from './constants';
import moment from 'moment';

export const canUseMarketplace = countryId => {
  if (countryId) {
    return ORDERS_MODULE_COUNTRY_CONFIGS[countryId].useMarketPlace;
  }
  return false;
};

export function getUserFacilityFromScopes(user) {
  let facility = null;
  user.scopes.forEach(scope => {
    if (scope.indexOf('facility.') >= 0) {
      facility = scope.split('facility.')[1];
    } else {
      facility = user.facility_id;
    }
  });
  return facility;
}

function handleWarehouseAndLogisticsLinks(userApps, links) {
  if (userApps?.some(app => app.app.name === 'Warehouse & Logistic')) {
    links.push({
      Icon: WarehouseIcon,
      tooltip: 'home.warehouseLogistics',
      HomeIcon: HomeWarehouseIcon,
      category: 'Pharmacy_Management',
      handleClick: () => window.open(LOGISTICS_APP_URL, '_blank')
    });
  }
}

function handleHealthServicesLinks(userApps, links, history) {
  if (userApps?.some(app => app.app.name === 'Health Services')) {
    links.push({
      Icon: HealthServicesIcon,
      tooltip: 'home.healthServices',
      HomeIcon: HomeHealthServicesIcon,
      category: 'Pharmacy_Management',
      handleClick: () => history.push(`/health-services/modules`)
    });
  }
}

function handleVDLLinks(userApps, links, history) {
  if (userApps && userApps.some(app => app.app.name === 'VDL')) {
    links.push({
      Icon: VDLIcon,
      tooltip: 'home.vdl',
      HomeIcon: VDLIcon,
      category: 'Pharmacy_Management',
      handleClick: () => history.push(`/vdl/overview`)
    });
  }
}

function handlePOSMuttiLinks(userApps, links, history) {
  if (userApps?.some(app => app.app.name === 'POS')) {
    links.push({
      Icon: MemberDirectoryIcon,
      tooltip: 'home.memberDirectory',
      HomeIcon: MemberDirectoryIcon,
      category: 'My_Mutti',
      handleClick: () => history.push(`/pos/member-directory`)
    });
  }
}

function handleNonPPMVNorVMILinks(userApps, contractType, links, history) {
  if (userApps?.some(app => app.app.name === 'POS')) {
    contractType !== 'VMI' &&
      contractType !== 'PPMV' &&
      links.push({
        Icon: MemberManagementIcon,
        HomeIcon: HomeMemberManagementIcon,
        tooltip: 'home.memberManagement',
        category: 'My_Mutti',
        handleClick: () => history.push(`/pos/member-management`)
      });
  }
}

function handleOrderLinks(user, userApps, links, history) {
  if (userApps?.some(app => app.app.name === 'Order')) {
    links.push({
      Icon: OrdersIcon,
      HomeIcon: HomeOrdersIcon,
      tooltip: canUseMarketplace(user?.location) ? 'home.marketplace' : 'home.orders',
      category: 'Pharmacy_Management',
      handleClick: () => history.push(`/orders/orders`)
    });
  }
}

function handleInventoryLinks(userApps, links, history) {
  if (userApps?.some(app => app.app.name === 'Inventory')) {
    links.push({
      Icon: InventoryIcon,
      HomeIcon: HomeInventoryIcon,
      tooltip: 'home.inventory',
      category: 'Pharmacy_Management',
      handleClick: () => history.push(`/inventory/all`)
    });
  }
}

function handleFacilityInsightsLinks(userApps, links, history) {
  if (userApps?.some(app => app.app.name === 'Analytics')) {
    links.push({
      Icon: FacilityInsightsIcon,
      HomeIcon: HomeFacilityInsightsIcon,
      tooltip: 'home.facilityInsights',
      category: 'Pharmacy_Management',
      handleClick: () => history.push(`/facility-insights/overview`)
    });
  }
}

function handlePOSPharmacyManagementLinks(userApps, links, history) {
  if (userApps?.some(app => app.app.name === 'POS')) {
    links.push({
      Icon: POSIcon,
      tooltip: 'home.pos',
      HomeIcon: HomePOSIcon,
      category: 'Pharmacy_Management',
      handleClick: () => history.push(`/pos/patient`)
    });
    links.push({
      Icon: SalesIcon,
      HomeIcon: HomeSalesIcon,
      tooltip: 'home.salesHistory',
      category: 'Pharmacy_Management',
      handleClick: () => history.push(`/pos/sales`)
    });
  }
}

export function renderAppLinks(user, history, contractType) {
  const userApps = user && user.userapps && JSON.parse(user.userapps);

  const links = [];

  handlePOSPharmacyManagementLinks(userApps, links, history);

  handleFacilityInsightsLinks(userApps, links, history);

  handleInventoryLinks(userApps, links, history);

  handleOrderLinks(user, userApps, links, history);

  handleNonPPMVNorVMILinks(userApps, contractType, links, history);

  handlePOSMuttiLinks(userApps, links, history);

  handleHealthServicesLinks(userApps, links, history);

  handleVDLLinks(userApps, links, history);

  handleWarehouseAndLogisticsLinks(userApps, links);

  return links;
}

export function showExpiryNotification(route) {
  const notificationRoutes = ['/', '/home'];
  return !!(notificationRoutes.includes(route) || route?.includes('/inventory'));
}

export function hasAccessToFacilityInsights(user) {
  return JSON.parse(user?.userapps || '[]').some(app => app.app.name === 'Analytics');
}

export function showCheckFINotification(route, user) {
  const notificationRoutes = ['/', '/home'];

  return !!(notificationRoutes.includes(route) && hasAccessToFacilityInsights(user));
}

export function showMpharmaWrappedPrompt(user) {
  const hasSeenWrappedNotification = localStorage.getItem('bloom:fi-hasSeenWrappedNotification');

  //If user has seen this notification before, or doesn't have access to fi module, hide it.
  return !(hasSeenWrappedNotification || !hasAccessToFacilityInsights(user));
}

export function showMpharmaWrappedNotification() {
  return false; //Set to false for now until mPharmaWrapped is automated.
}

export function getCookie(cname) {
  return Cookies.get(cname);
}

export function setCookie(cname, value) {
  Cookies.set(cname, value, { domain: 'mpharma.com' });
}

export function deleteCookie(cname) {
  Cookies.remove(cname, { domain: 'mpharma.com' });
}

export function getAllCookieNames() {
  const allCookies = document.cookie.split('; ').map(cookie => cookie.split('=')[0]);
  return allCookies;
}

export function deleteAllCookies() {
  const cookieNames = getAllCookieNames();

  for (const cookieName of cookieNames) {
    deleteCookie(cookieName);
  }
}

export function getDefaultFetchOptions() {
  const idToken = getCookie('idToken');
  return {
    mode: 'cors',
    headers: {
      'M-USER-JWT': idToken,
      Authorization: `Bearer ${idToken}`
    }
  };
}

export function requestInterceptor(config) {
  const idToken = getCookie('idToken');

  config.headers['M-USER-JWT'] = idToken;
  config.headers['Authorization'] = `Bearer ${idToken}`;

  if (!idToken) {
    let path = String(window.location.href);
    window.location.href = `${LOGOUT_URL}?redirectUrl=${path}`;
  }
  return config;
}

export function responseInterceptor(response) {
  if (response && response.status === 401) {
    let path = String(window.location.href);
    window.location.href = `${LOGOUT_URL}?redirectUrl=${path}`;
  }

  return response;
}

export function capitalizeString(str) {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

export const getInitials = (firstname, lastname) =>
  `${String(firstname[0]).toUpperCase()}${String(lastname[0]).toUpperCase()}`;

export async function trackResourceOperationTime(payload, resource, operation, fetchOptions) {
  const { name, start_time, end_time, facility_id, number_of_records } = payload;
  if (name) {
    try {
      const response = await fetch(INVENTORY_OPERATIONS_TRACKING_URL, {
        method: 'POST',
        ...fetchOptions,
        body: JSON.stringify({
          name: name,
          facility_id: facility_id,
          start_time,
          end_time,
          number_of_records,
          operation_type: INVENTORY_OPERATION_TYPES[resource][operation]
        })
      });
      const operationTrackingResponse = await response.json();
      if (operationTrackingResponse)
        console.log(capitalizeString(resource) + ' ' + capitalizeString(operation) + ' Time Saved Successfully');
    } catch (_err) {
      console.log(
        'An error occurred while saving ' + capitalizeString(resource) + capitalizeString(operation) + 'Time'
      );
    }
  }
}

export const userSystemTimeZoneMatchesFacilityTimezone = facilityCountryCode => {
  const userSystemTimeZoneUtcOffset = new Date().getTimezoneOffset();
  const facilityTimeZoneUtcOffset = FACILITY_TIME_ZONE_UTC_OFFSETS[facilityCountryCode];
  return userSystemTimeZoneUtcOffset === facilityTimeZoneUtcOffset;
};

export const getCurrentBloomVersion = () => {
  try {
    const versions = JSON.parse(localStorage.getItem('bloom:versions'));
    return versions;
  } catch (error) {
    return undefined;
  }
};

export const setCurrentVersionToLocal = version => {
  const bloomrootVersion = {
    bloom: {
      version: version,
      key: 'home.bloom'
    }
  };
  try {
    const bloomVersions = JSON.parse(localStorage.getItem('bloom:versions'));
    if ('bloom' in bloomVersions) {
      bloomVersions.bloom['version'] = version;
      localStorage.setItem('bloom:versions', JSON.stringify(bloomVersions));
    } else {
      localStorage.setItem(
        'bloom:versions',
        JSON.stringify({
          ...bloomVersions,
          ...bloomrootVersion
        })
      );
    }
  } catch (error) {
    localStorage.setItem('bloom:versions', JSON.stringify(bloomrootVersion));
  }
};

export function sortErrorsByType(errors) {
  return errors.reduce((acc, error) => {
    const errorType = error.error_type;
    if (!acc[errorType]) {
      acc[errorType] = [];
    }
    acc[errorType].push(error);
    return acc;
  }, {});
}

export const hasDayPassed = date => {
  if (!date) return true;
  return moment().diff(moment(date), 'days') >= 1;
}

export const handleKeyDownEnterOrSpace = (callback) => (event) => {
  if (event.key === 'Enter' || event.key === ' ') {
    event.preventDefault(); 
    callback(); 
  }
};
